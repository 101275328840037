<template>
  <div>
    <form-wizard
      color="#236b53"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="addCorporate"
    >
      <!-- Corporate details tab -->
      <tab-content title="Corporate Detail">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Corporate Detail
            </h5>
            <small class="text-muted">
              Enter Your Corporate Details.
            </small>
          </b-col>
          <b-col md="12">
            <b-form-group label="Corporate Name" label-for="corporate_name">
              <b-form-input
                id="corporate_name"
                v-model="dataInput.name"
                placeholder="Corporate Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Address" label-for="address">
              <b-form-textarea
                id="address"
                v-model="dataInput.address"
                placeholder="Address"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Country" label-for="country">
              <b-form-select 
                id="country"
                v-model="dataInput.country"
                @change="getState()"
                :options="countryOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="State" label-for="state">
              <b-form-select 
                id="state"
                v-model="dataInput.state"
                @change="getCity()"
                :options="stateOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="City" label-for="city">
              <b-form-select
                id="city" 
                v-model="dataInput.city"
                :options="cityOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Postal Code" label-for="postal_code">
              <b-form-input
                id="postal_code"
                v-model="dataInput.postal_code"
                placeholder="Postal Code"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Phone Number" label-for="phone">
              <b-input-group :prepend="dataInput.phone_code">
                <b-form-input 
                  id="phone"
                  v-model="dataInput.phone"
                  placeholder="Phone"/>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Telephone Number" label-for="phone">
              <b-input-group :prepend="dataInput.telephone_code">
                <b-form-input 
                  id="phone"
                  v-model="dataInput.telephone"
                  placeholder="Telephone"/>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Website" label-for="website">
              <b-form-input 
                id="website"
                v-model="dataInput.website"
                placeholder="Website"/>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Commerce Group" label-for="commerce_group">
              <b-form-input 
                id="commerce_group"
                v-model="dataInput.commerce_group"
                placeholder="Commerce Group"/>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Description" label-for="description">
              <b-form-textarea
                id="description"
                v-model="dataInput.description"
                placeholder="Description"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
      
      <!-- Corporate admin tab -->
      <tab-content title="Corporate Admin">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Corporate Admin
            </h5>
            <small class="text-muted">
              Enter Your Corporate Admin.
            </small>
          </b-col>
          <b-col md="4">
            <b-form-group label="First Name" label-for="first_name">
              <b-form-input
                id="first_name"
                v-model="dataInput.first_name"
                placeholder="First Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Middle Name" label-for="middle_name">
              <b-form-input
                id="middle_name"
                v-model="dataInput.middle_name"
                placeholder="Middle Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Last Name" label-for="last_name">
              <b-form-input
                id="last_name"
                v-model="dataInput.last_name"
                placeholder="Last Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                type="email"
                v-model="dataInput.email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Username" label-for="username">
              <b-form-input
                id="username"
                v-model="dataInput.username"
                placeholder="Username"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import Ripple from 'vue-ripple-directive'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import {
    BRow, BCol,
    BCardBody,
    BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
    BFormGroup, BFormSelect, BFormCheckbox, BFormTextarea, BButton
  } from 'bootstrap-vue'

  export default {
    data() {
      return {
        dataInput: {
          name: null,
          first_name: null,
          middle_name: null,
          last_name: null,
          phone: null,
          phone_code: null,
          telephone: null,
          telephone_code: null,
          address: null,
          country: null,
          state: null,
          city: null,
          postal_code: null,
          npwp: null,
          website: null,
          commerce_group: null,
          description: null,
          email: null,
          username: null
        },
        countryData: [],
        countryOptions: [],
        stateOptions: [],
        cityOptions: []
      }
    },
    components: {
      FormWizard, TabContent,
      BRow, BCol,
      BCardBody,
      BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
      BFormGroup, BFormSelect, BFormCheckbox, BFormTextarea, BButton
    },
		directives: {
			Ripple,
		},
    created() {
      this.checkAuth()
    },
    mounted() {
      this.getCountry()
    },
    methods: {
      getCountry() {
        this.$axios.get('all/csc/countries')
          .then(res => {
            const { data } = res.data
            
            this.countryData = data
            data.forEach(item => {
              let country = {
                value: item.id,
                text: item.name,
              }

              this.countryOptions.push(country)
            })
          })
      },
      getState() {
        let country = this.dataInput.country

        let countryFilter = this.countryData.filter(item => {
          return item.id == country
        })
        this.dataInput.phone_code = countryFilter[0].phonecode
        this.dataInput.telephone_code = countryFilter[0].phonecode

        this.$axios.get(`all/csc/countries/${country}/states`)
          .then(res => {
            const { data } = res.data

            this.stateOptions = []
            this.cityOptions = []
            data.forEach(item => {
              let state = {
                value: item.id,
                text: item.name
              }

              this.stateOptions.push(state)
            })
          })
      },
      getCity() {
        let state = this.dataInput.state

        this.$axios.get(`all/csc/states/${state}/city`)
          .then(res => {
            const { data } = res.data

            this.cityOptions = []
            data.forEach(item => {
              let city = {
                value: item.id,
                text: item.name
              }

              this.cityOptions.push(city)
            })
          })
      },

      addCorporate() {
        let cid = localStorage.getItem('client_id')
        let data = this.dataInput

        let first_name = (data.first_name === null) ? '' : `${data.first_name}`
        let mid_name = (data.middle_name === null) ? '' : ` ${data.middle_name}`
        let last_name = (data.last_name === null) ? '' : ` ${data.last_name}`
        let name = `${first_name}${mid_name}${last_name}`

        let form_data = {
          client_id: cid,
          email: data.email,
          username: data.username,
          first_name: first_name,
          middle_name: (data.middle_name === null) ? null : data.middle_name,
          last_name: last_name.trim(),
          name: data.name,
          contact_name: name,
          phone_code: data.phone_code,
          phone: data.phone,
          telephone_code: data.telephone_code,
          telephone: data.telephone,
          address: data.address,
          country: data.country,
          state: data.state,
          city: data.city,
          postal_code: data.postal_code,
          npwp: data.npwp,
          website: data.website,
          commerce_group: data.commerce_group,
          description: data.description
        }

        this.$axios.post(`${cid}/corporate`, form_data)
          .then(res => {
            // Success code
            this.$bvToast.toast('Success', {
              title: 'Add data coprorate',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right',
              autoHideDelay: 3000
            })

            setTimeout(() => {
              this.$router.push({ path: '/user/corporate' })
            }, 3000);
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>